@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: Inter;
  src: url('../src/assets/Inter/static/Inter-Regular.ttf');
}

*,
html,
body {
  padding: 0px;
  margin: 0px;
  font-family: 'Inter', sans-serif;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  background-color: #3f4254;
  height: 100vh;
  color: azure;
}
.boxContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  gap: 20px;
  padding: 20px;
}

.form-input {
  @apply appearance-none bg-clip-padding rounded-md block font-medium text-sm m-0 px-6 py-3 w-full outline-none min-h-[45px];
  color: rgb(102, 107, 128, 1);
  /* background-color: rgba(245, 248, 250, 1); */
  border-color: rgba(180, 182, 198, 1);
  transition:
    color 0.2s ease 0s,
    background-color 0.2s ease 0s;
}
/* styles.css */
.button {
  padding-left: 1rem; /* px-4 */
  padding-right: 1rem; /* px-4 */
  padding-top: 0.75rem; /* py-3 */
  padding-bottom: 0.75rem; /* py-3 */
  font-weight: 600; /* font-semibold */
  color: white; /* text-white */
  border-radius: 0.5rem; /* rounded-lg */
  font-size: 13px; /* text-[13px] */
  line-height: 1rem; /* leading-4 */
  background-color: #f97316; /* bg-orange-501 */
  cursor: pointer; /* cursor-pointer */
  width: 100%; /* w-full */
  max-width: 20%;
  transition:
    background-color 0.3s,
    opacity 0.3s; /* smooth transitions for background and opacity */
}

.button:hover {
  background-color: #fb923c; /* hover:bg-orange-500 */
}

.button:focus {
  opacity: 0.9; /* focus:opacity-90 */
  outline: none; /* Remove default outline for better custom focus styling */
}

.button.disabled {
  cursor: not-allowed; /* cursor-not-allowed */
  background-color: #d4d4d8; /* bg-zinc-300 */
}

.button.disabled:hover {
  background-color: #d4d4d8; /* hover:bg-zinc-300 */
}

.pdfClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 50%;
  overflow-y: auto;
}

.draggable-box {
  border: 2px dashed #000;
  background: rgba(0, 0, 0, 0.1);
  cursor: move;
  resize: both;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px !important;
  background: rgb(224, 224, 218);
  border-radius: 12px;
}
::-webkit-scroller {
  width: 6px;
  background: #000;
  border-radius: 100%;
}

::-webkit-scrollbar-thumb {
  background: #9a9aa6;
  width: 6px;

  border-radius: 100%;
  -webkit-border-radius: 20px;
}
