@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');

body{
  background: #f6f9fb;
}
div.profileImage {
  width: 30px;
  border: 2px solid #fff;
  border-radius: 8px;
  height: 30px !important;
  vertical-align: middle !important;
  background: #cd4927;
  text-align: center;
  line-height: 26px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 16px;
}
